import React, { Component } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  withStyles
} from "@material-ui/core";
import { inject, observer } from "mobx-react";
import { Decimal } from "decimal.js-light";
import { PAYMENT_MODES } from "../../../stores/NewOrderFormStore";
import { formatCurrency } from "../../../utils/numberFormat";
import DepositForm from "./DepositForm";

const styles = theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  item: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  group: {
    flexDirection: "row"
  }
});

@inject("newOrderFormStore")
@observer
class CashForm extends Component {
  componentDidMount() {
    // Used for the ref to work.
    // https://v3-9-0.material-ui.com/demos/text-fields/#components
    // This will probably be better with V4 of MU
    this.forceUpdate();
  }

  /**
   * Compute the data for the TextField.
   */
  getTextFieldValues = amount => ({
    cashPrice: amount,
    deposit: new Decimal(amount)
      .mul(0.3)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2),
    balance: new Decimal(amount)
      .mul(0.7)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2)
  });

  render() {
    const { classes, newOrderFormStore } = this.props;

    return (
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} className={classes.item}>
          <FormControl component="fieldset" required>
            <FormLabel component="legend">Moyen de paiement</FormLabel>
            <RadioGroup
              aria-label="paymentMode"
              name="paymentMode"
              className={classes.group}
              value={newOrderFormStore.paymentMode}
              onChange={newOrderFormStore.handleChange}
            >
              {PAYMENT_MODES.map(method => (
                <FormControlLabel
                  key={method.value}
                  value={method.value}
                  control={<Radio color="primary" />}
                  label={method.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <Typography variant="body1">
            Prix au comptant :{" "}
            <span style={{ fontWeight: 500 }}>
              {formatCurrency(newOrderFormStore.amount, newOrderFormStore.currency)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <DepositForm />
        </Grid>

        <Grid item xs={12} className={classes.item}>
          <Typography variant="body1">
            Total des acomptes :{" "}
            <span style={{ fontWeight: 500 }}>
              {formatCurrency(newOrderFormStore.computedCashFormDeposits, newOrderFormStore.currency)}
            </span>
          </Typography>
          <Typography variant="body1">
            Solde à la réception :{" "}
            <span
              style={{
                fontWeight: 500,
                color: newOrderFormStore.computedCashFormBalance < 0 ? "red" : "inherit"
              }}
            >
              {formatCurrency(newOrderFormStore.computedCashFormBalance, newOrderFormStore.currency)}
            </span>
          </Typography>
          {newOrderFormStore.computedCashFormBalance < 0 && (
            <Typography variant="caption" style={{ color: "red" }}>
              Le solde ne peut être négatif
            </Typography>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(CashForm);
