import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  makeStyles
} from "@material-ui/core";
import React from "react";
import { useSnackbar } from "notistack";
import AdminService from "../../../services/AdminService";

const useStyles = makeStyles(theme => ({
  link: { display: "block" },
  card: { height: "100%" },
  row: {
    display: "flex",
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  },
  rowPicto: {
    flexBasis: 40,
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  },
  companyItem: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing()
  }
}));

const clientPrefixes = Array.from({ length: 10 }, (_, index) => index);

const DEFAULT_GLOBAL_CONFIGURATION = {
  cegidClientPrefix: ""
};

export default function CompanyGlobalConfiguration({ company, onPatch }) {
  const classes = useStyles();
  // const [mailConfiguration, setMailConfiguration] = React.useState(company.mailConfiguration);
  const [globalConfiguration, setGlobalConfiguration] = React.useState({
    ...DEFAULT_GLOBAL_CONFIGURATION,
    ...company.globalConfiguration
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    AdminService.patchCompany({
      ...company,
      globalConfiguration
    })
      .then(updatedCompany => {
        onPatch(updatedCompany);
        enqueueSnackbar("L'entreprise a été mise à jour", { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour de l'entreprise", {
          variant: "error"
        });
      });
  };

  const handleReset = () => {
    setGlobalConfiguration(company.globalConfiguration);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h3">
              Configuration globale
            </Typography>
            <Box style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Box>
                <FormControl variant="outlined" size="small" fullWidth>
                  <Typography variant="body2" gutterBottom>
                    CEGID Quadra - Préfixe des numéros clients
                  </Typography>
                  <Select
                    fullWidth
                    value={globalConfiguration.cegidClientPrefix}
                    onChange={e =>
                      setGlobalConfiguration({ ...globalConfiguration, cegidClientPrefix: e.target.value })
                    }
                    displayEmpty
                    variant="outlined"
                    style={{ textAlign: "left" }}
                  >
                    <MenuItem value="">
                      <em> -- Aucun préfixe --</em>
                    </MenuItem>
                    {clientPrefixes.map(prefix => (
                      <MenuItem key={prefix} value={prefix}>
                        {prefix}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </CardContent>
          <CardActions style={{ justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleReset}>
              Annuler
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Enregistrer
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}
