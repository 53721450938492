import React, { Component } from "react";
import { withStyles, AppBar, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withSnackbar } from "notistack";

import ProductService from "../../services/ProductService";
import AddProductForm from "../form/AddProductForm";
import { TransitionUp } from "../utils/transitions/TransitionUp";

const styles = theme => ({
  appBar: {
    position: "relative"
  },
  flex: {
    flex: 1
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const DEFAULT_CUSTOM_FIELD = { label: "", required: false, value: "" };
const CUSTOM_FIELDS_LIMIT = 10;

const DEFAULT_PRODUCT = {
  categoryId: "",
  reference: "",
  title: "",
  description: "",
  tva: "20.00",
  price: "",
  customFields: [DEFAULT_CUSTOM_FIELD],
  accountNumber: ""
};

class AddEditProductDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
      product: { ...DEFAULT_PRODUCT }
    };
  }

  componentWillReceiveProps(props) {
    let product = DEFAULT_PRODUCT;
    if (props.currentProduct && props.currentProduct.customFields.length < CUSTOM_FIELDS_LIMIT) {
      product = { ...props.currentProduct, accountNumber: props.currentProduct.accountNumber || "" };
      product.customFields.push(DEFAULT_CUSTOM_FIELD);
    }
    if (props.currentProduct && props.currentProduct.category) {
      product.categoryId = props.currentProduct.category.id;
    } else {
      product.categoryId = "";
    }
    this.setState({ product, open: props.open });
  }

  handleClose = () => {
    const { handleClose } = this.props;
    if (handleClose) {
      handleClose();
      this.setState({ product: DEFAULT_PRODUCT });
    } else {
      this.setState({ open: false, product: DEFAULT_PRODUCT });
    }
  };

  handleSubmit = prod => {
    const { getAllProducts, onSuccess } = this.props;

    this.setState({ loading: true }, () => {
      ProductService.postProduct(prod)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("La prestation a été créée", { variant: "success" });
          this.setState({ open: false, loading: false, product: { ...DEFAULT_PRODUCT } });
          onSuccess();
          getAllProducts();
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations du produit sont incorrectes"
              : "Une erreur est survenue lors de la création du produit",
            {
              variant: "error"
            }
          );
          this.setState({ loading: false });
        });
    });
  };

  handleEditSubmit = prod => {
    const { getAllProducts, onSuccess } = this.props;
    this.setState({ loading: true }, () => {
      ProductService.patchProduct(prod)
        .then(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("La prestation a été modifiée", { variant: "success" });
          this.setState({
            open: false,
            loading: false,
            product: { ...DEFAULT_PRODUCT }
          });
          onSuccess();
          getAllProducts();
        })
        .catch(error => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar(
            error.status === 400
              ? "Les informations du produit sont incorrectes"
              : "Une erreur est survenue lors de la mise à jour du produit",
            {
              variant: "error"
            }
          );
          this.setState({ loading: false });
        });
    });
  };

  render() {
    const { classes } = this.props;
    const { open, loading, product } = this.state;

    return (
      <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={TransitionUp}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              {product.id ? "Modifier la prestation" : "Ajouter une prestation"}
            </Typography>
          </Toolbar>
        </AppBar>
        <AddProductForm
          product={product}
          customFieldsLimit={CUSTOM_FIELDS_LIMIT}
          isSubmitting={loading}
          handleSubmitForm={product.id ? this.handleEditSubmit : this.handleSubmit}
          handleCancel={this.handleClose}
        />
      </Dialog>
    );
  }
}

export default withSnackbar(withStyles(styles)(AddEditProductDialog));
