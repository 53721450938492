import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  Chip
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/fr";
import { useSnackbar } from "notistack";
import Decimal from "decimal.js-light";

import { useQueryClient } from "@tanstack/react-query";
import NewPaymentForm from "../payment/NewPaymentForm";
import { INVOICE_STATUS } from "../../utils/status";
import { ensureTwoDigits, formatNumber } from "../../utils/numberFormat";
import InvoicePaymentComment from "../payment/InvoicePaymentComment";
import PaymentRow from "../payment/PaymentRow";
import PaymentService from "../../services/PaymentService";
import InvoiceService from "../../services/InvoiceService";

export default function InvoicePaymentsManager({ invoice, onClose, open }) {
  if (!open) {
    return <div />;
  }
  const { enqueueSnackbar } = useSnackbar();
  const [comment, setComment] = useState(invoice.paymentComments || "");
  const [payments, setPayments] = useState(invoice.payments || []);
  const [isTotallyPaid, setIsTotallyPaid] = useState(invoice.status === "PAID");
  const invoiceAmountAsDecimal = ensureTwoDigits(invoice.amount);
  const [remain, setRemain] = useState(
    invoiceAmountAsDecimal - ensureTwoDigits((invoice.payments || []).reduce((a, b) => a + (+b.amount || 0), 0))
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    const paidAsDecimal = ensureTwoDigits(payments.reduce((a, b) => a + (+b.amount || 0), 0));
    setIsTotallyPaid(paidAsDecimal === invoiceAmountAsDecimal);
    const _remain = new Decimal(invoiceAmountAsDecimal)
      .sub(paidAsDecimal)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
    setRemain(_remain);
  }, [payments]);

  useEffect(() => {
    if (isTotallyPaid && invoice.status !== "PAID") {
      enqueueSnackbar(`Le statut de la facture ${invoice.number} est maintenant PAYÉE`, { variant: "success" });
    }
  }, [isTotallyPaid]);

  const handleSubmitComment = async paymentComments => {
    InvoiceService.postInvoiceComment({ invoiceId: invoice.id, paymentComments })
      .then(() => {
        setComment(paymentComments);
        enqueueSnackbar("Le commentaire a été modifié", {
          variant: "success"
        });
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue lors de la mise à jour du commentaire", {
          variant: "error"
        });
      });
  };

  const postPayment = payment => PaymentService.createPayment({ payment, invoiceId: invoice.id });

  const patchPayment = payment => PaymentService.patchPayment({ payment, invoiceId: invoice.id });

  const onPaymentSubmitted = async payment => {
    setPayments([...payments, { ...payment }]);
    queryClient.invalidateQueries({ queryKey: ["payment_stats"] });
  };

  const handleDelete = paymentId => {
    setPayments([...payments.filter(payment => payment.id !== paymentId)]);
    queryClient.invalidateQueries({ queryKey: ["payment_stats"] });
  };

  const handlePaymentEdition = payment => {
    setPayments(payments.map(pmnt => (pmnt.id !== payment.id ? pmnt : { ...payment })));
    queryClient.invalidateQueries({ queryKey: ["payment_stats"] });
  };

  return (
    <Dialog
      modal="false"
      open
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">Gestion des paiements - Facture {invoice.number}</DialogTitle>
      <DialogContent>
        {invoice && (
          <Fragment>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={4}
                style={{
                  borderRight: "1px solid #E3E3E3",
                  paddingTop: 16,
                  paddingBottom: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Typography variant="h5" style={{ textAlign: "center" }}>
                  {isTotallyPaid ? (
                    <Chip
                      label={INVOICE_STATUS.PAID.label}
                      style={{
                        backgroundColor: INVOICE_STATUS.PAID.color,
                        color: "white"
                      }}
                    />
                  ) : (
                    <span style={{ fontSize: "0.7em" }}>
                      <span style={{ opacity: "0.7" }}>Reste à percevoir</span>
                      <br />
                      <span style={{ opacity: "0.9" }}>{formatNumber(remain)} €</span>
                    </span>
                  )}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <InvoicePaymentComment comment={comment} handleSubmit={handleSubmitComment} />
              </Grid>
            </Grid>
            {!isTotallyPaid && (
              <div style={{ marginTop: 16, marginBottom: 20 }}>
                <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                  Saisie d&apos;un nouveau paiement
                </Typography>
                <NewPaymentForm
                  minDate={moment(invoice.createAt)}
                  maxPrice={Math.round((remain + Number.EPSILON) * 100) / 100}
                  onPaymentSubmitted={onPaymentSubmitted}
                  submitPayment={postPayment}
                />
              </div>
            )}
            <div style={{ marginBottom: 16, marginTop: isTotallyPaid ? 16 : 0 }}>
              <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
                Historique des paiements
              </Typography>
              {payments.length > 0 ? (
                <div style={{ maxHeight: isTotallyPaid ? 272 : 185, overflowY: "scroll" }}>
                  <Table aria-labelledby="tableTitle" style={{ minWidth: 575 }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 2 }}>
                          Date
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 2 }}>
                          Type
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 2 }}>
                          Montant (TTC)
                        </TableCell>
                        <TableCell style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 2 }}>
                          Commentaire
                        </TableCell>
                        <TableCell
                          style={{ backgroundColor: "#fff", position: "sticky", top: 0, zIndex: 2 }}
                          align="center"
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payments
                        .sort((a, b) => (a.date < b.date ? 1 : -1))
                        .map((payment, index) => (
                          <PaymentRow
                            key={`payment-${index + 1}`}
                            payment={payment}
                            handleDelete={handleDelete}
                            editPayment={patchPayment}
                            onPaymentEdited={handlePaymentEdition}
                            maxPrice={Math.round((remain + Number.EPSILON) * 100) / 100}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <Typography variant="body2" align="center">
                  Aucun paiement enregistré pour cette facture
                </Typography>
              )}
            </div>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined" autoFocus>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
