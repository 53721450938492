import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React, { Component, Fragment } from "react";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ExportToCsv } from "export-to-csv";
import {
  Fab,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
  Button,
  CircularProgress,
  TablePagination,
  withWidth,
  Menu,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import { withSnackbar } from "notistack";

import AddEditProductDialog from "../dialog/AddEditProductDialog";
import ProductService, { formatProductAdditionnalField } from "../../services/ProductService";
import ProductsListFilter from "./ProductsListFilter";
import ProductRow from "./ProductRow";

const options = {
  fieldSeparator: ";",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
  filename: `export-catalogue-${Date.now()}`
};
const csvExporter = new ExportToCsv(options);

const ITEMS_PER_PAGE = 25;
const defaultFilter = {
  q: "",
  category: []
};

class ProductListing extends Component {
  constructor(props) {
    super(props);
    const searchParams = new URLSearchParams(props.location.search);
    this._isMounted = false;
    this.state = {
      products: null,
      filteredProductsList: null,
      openModal: false,
      currentProduct: null,
      filter: {
        q: "",
        category: searchParams.get("categoryId") ? [searchParams.get("categoryId")] : []
      },
      currentPage: 0,
      gonnaBeRefreshed: false,
      // menuOpenFor: null,
      selection: [],
      anchorEl: null
    };
  }

  componentDidMount() {
    this.getAllProducts();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getAllProducts = () => {
    const { productsStatus } = this.props;
    this.setState({ gonnaBeRefreshed: true }, () => {
      ProductService.getProducts({ productsStatus })
        .then(response => {
          if (this._isMounted) {
            this.setState(
              {
                products: response.content,
                gonnaBeRefreshed: false,
                currentPage: 0
              },
              () => {
                this.handleFilter();
              }
            );
          }
        })
        .catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la récupération du catalogue", {
            variant: "error"
          });
          if (this._isMounted) {
            this.setState({
              gonnaBeRefreshed: false
            });
          }
        });
    });
  };

  handleReset = () => {
    const { history } = this.props;
    history.push("/catalogue");
  };

  updateFilter = filter => {
    if (this._isMounted) {
      this.setState({ filter });
    }
  };

  handleClickOpen = () => {
    this.setState({ openModal: true, currentProduct: null });
  };

  handleClickClose = () => {
    this.setState({ openModal: false });
  };

  handleEdit = product => {
    this.setState({ currentProduct: product, openModal: true });
  };

  handleDeletion = productId => {
    ProductService.deleteProduct(productId)
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Produit supprimé", { variant: "success" });
        this.getAllProducts();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la suppression du produit", {
          variant: "error"
        });
      });
  };

  handleFilter = () => {
    const { filter } = this.state;
    this.setState({
      filteredProductsList: this.filterProducts(filter),
      currentPage: 0
    });
  };

  exportCSV = () => {
    const { filteredProductsList } = this.state;
    const filteredProducts = filteredProductsList.map(
      ({ title, description, price, tva, category, reference, customFields }) => {
        const customFieldsFormatted =
          customFields && customFields.length > 0 ? customFields.map(formatProductAdditionnalField).join("\n") : "";
        return {
          categoryName: category && category.name ? category.name : "",
          reference: reference || "",
          title,
          description,
          tva,
          price,
          customFields: customFieldsFormatted
        };
      }
    );
    csvExporter.generateCsv(filteredProducts);
  };

  handleExportSelection = () => {
    const { selection, products } = this.state;
    const filteredProducts = products
      .filter(product => selection.includes(product.id))
      .map(({ title, description, price, tva, customFields, reference, category }) => {
        const customFieldsFormatted =
          customFields && customFields.length > 0 ? customFields.map(formatProductAdditionnalField).join("\n") : "";
        return {
          categoryName: category && category.name ? category.name : "",
          reference: reference || "",
          title,
          description,
          tva,
          price,
          customFields: customFieldsFormatted
        };
      });
    csvExporter.generateCsv(filteredProducts);
    this.handleCloseManageRowsMenu();
  };

  handleChangePage = (event, page) => {
    this.setState({
      currentPage: page
    });
  };

  filterProducts = ({ q, category }) => {
    const {
      products,
      filter: { q: query }
    } = this.state;
    const _q = q || q === "" ? q : query;

    const qFilterred = _q
      ? products.filter(
          product =>
            product.title.toLowerCase().includes(_q.toLowerCase()) ||
            product.description.toLowerCase().includes(_q.toLowerCase()) ||
            (product.reference && product.reference.toLowerCase().includes(_q.toLowerCase()))
          // (product.category && product.category.name.toLowerCase().includes(_q.toLowerCase()))
        )
      : products;
    const categoryFilterred =
      category.length > 0
        ? qFilterred.filter(product => (product.category ? category.includes(product.category.id) : false))
        : qFilterred;
    return categoryFilterred;
  };

  toggleSelectionFor = (productId, enforcedState = undefined) => {
    const { selection } = this.state;
    const productInSelection = selection.includes(productId);
    if ((enforcedState === undefined && productInSelection) || enforcedState === false) {
      this.setState({ selection: selection.filter(id => id !== productId) });
    } else {
      this.setState({ selection: [...selection, productId] });
    }
  };

  handleOpenManageRowsMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseManageRowsMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleDisableProduct = product => {
    ProductService.manageProducts([product.id], "DEACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Produit désactivé", {
          variant: "success"
        });
        this.getAllProducts();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la désactivation", {
          variant: "error"
        });
      });
  };

  handleEnableProduct = product => {
    ProductService.manageProducts([product.id], "ACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Produit activé", {
          variant: "success"
        });
        this.getAllProducts();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'activation", {
          variant: "error"
        });
      });
  };

  handleDisableSelection = () => {
    const { selection } = this.state;
    ProductService.manageProducts(selection, "DEACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(`Produit${selection.length > 1 ? "s" : ""} désactivé${selection.length > 1 ? "s" : ""}`, {
          variant: "success"
        });
        this.getAllProducts();
        this.handleCloseManageRowsMenu();
        this.setState({ selection: [] });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de la désactivation", {
          variant: "error"
        });
        this.handleCloseManageRowsMenu();
      });
  };

  handleEnableSelection = () => {
    const { selection } = this.state;
    ProductService.manageProducts(selection, "ACTIVATE")
      .then(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar(`Produit${selection.length > 1 ? "s" : ""} activé${selection.length > 1 ? "s" : ""}`, {
          variant: "success"
        });
        this.getAllProducts();
        this.handleCloseManageRowsMenu();
        this.setState({ selection: [] });
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue lors de l'activation", {
          variant: "error"
        });
        this.handleCloseManageRowsMenu();
      });
  };

  render() {
    const { classes, width, productsStatus, sessionStore } = this.props;
    const {
      filteredProductsList,
      openModal,
      currentProduct,
      gonnaBeRefreshed,
      filter,
      currentPage,
      // menuOpenFor,
      selection,
      anchorEl
    } = this.state;

    const hasEditAccess = sessionStore.userHasAccess({
      requiredAcls: ["PRODUCT_WRITE"],
      requiredFeatures: ["PRODUCT"] // Native feature
    });

    const slicedList = filteredProductsList
      ? filteredProductsList.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
      : null;

    return (
      <Fragment>
        <Grid container className={classes.container}>
          <ProductsListFilter
            filter={filter}
            defaultFilter={defaultFilter}
            updateFilter={this.updateFilter}
            onSubmit={this.handleFilter}
            onReset={this.handleReset}
          />
        </Grid>

        {filteredProductsList && filteredProductsList.length > 0 && selection.length === 0 && (
          <div className={classes.actionsContainer}>
            <Button variant="outlined" color="primary" onClick={this.exportCSV}>
              Exporter en CSV
            </Button>
          </div>
        )}
        {selection.length > 0 && (
          <div className={classes.actionsContainer}>
            <Button
              aria-controls="simple-managing-menu"
              aria-haspopup="true"
              variant="outlined"
              color="primary"
              onClick={this.handleOpenManageRowsMenu}
              endIcon={<ArrowDropDownIcon />}
            >
              Actions sur la sélection ({selection.length})
            </Button>
            <Menu
              id="simple-export-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleCloseManageRowsMenu}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              {productsStatus === "ACTIVE" && (
                <ListItem button onClick={this.handleDisableSelection}>
                  <ListItemIcon>
                    <CancelIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary="Désactiver la sélection"
                    secondary="Désactive les produits sélectionnés du catalogue"
                  />
                </ListItem>
              )}
              {productsStatus === "INACTIVE" && (
                <ListItem button onClick={this.handleEnableSelection}>
                  <ListItemIcon>
                    <CheckCircleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Activer la sélection" secondary="Active les produits sélectionnés" />
                </ListItem>
              )}
              <ListItem button onClick={this.handleExportSelection}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Exporter la sélection"
                  secondary="Toutes les données des produits sélectionnés dans un fichier CSV"
                />
              </ListItem>
            </Menu>
          </div>
        )}

        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Paper className={classes.root}>
              <Table size="small" className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ maxWidth: 45, padding: 0 }}> </TableCell>
                    <TableCell>Catégorie</TableCell>
                    <TableCell style={{ minWidth: "20vw" }}>Dénomination / Référence</TableCell>
                    {width !== "xs" && <TableCell>Description</TableCell>}
                    <TableCell align="right">Prix HT</TableCell>
                    <TableCell align="right">TVA</TableCell>
                    {hasEditAccess && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredProductsList && gonnaBeRefreshed === false ? (
                    slicedList.length > 0 ? (
                      slicedList.map(product => (
                        <ProductRow
                          key={product.id}
                          product={product}
                          selected={selection.includes(product.id)}
                          onDelete={this.handleDeletion}
                          onEdit={this.handleEdit}
                          onDisable={this.handleDisableProduct}
                          onEnable={this.handleEnableProduct}
                          // patchMenuOpenFor={_menuOpenFor => this.setState({ menuOpenFor: _menuOpenFor })}
                          hasEditAccess={hasEditAccess}
                          toggleSelectionFor={this.toggleSelectionFor}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={width === "xs" ? 5 : 6} align="center" className={classes.loadingCell}>
                          {filter.q || filter.category.length > 0 || productsStatus === "INACTIVE"
                            ? "Aucun produit ne correspond à la recherche"
                            : "Vous n'avez pas encore saisi de produit"}
                        </TableCell>
                      </TableRow>
                    )
                  ) : (
                    <TableRow>
                      <TableCell colSpan={width === "xs" ? 5 : 6} align="center" className={classes.loadingCell}>
                        <CircularProgress size={40} />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {filteredProductsList && filteredProductsList.length > 0 && (
                <TablePagination
                  component="div"
                  count={filteredProductsList.length}
                  rowsPerPage={ITEMS_PER_PAGE}
                  rowsPerPageOptions={[ITEMS_PER_PAGE]}
                  page={currentPage}
                  backIconButtonProps={{
                    "aria-label": "Page précédente"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Page suivante"
                  }}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} sur ${count}`}
                />
              )}
            </Paper>
          </Grid>
          <AddEditProductDialog
            open={openModal}
            getAllProducts={this.getAllProducts}
            onSuccess={() => this.setState({ openModal: false })}
            currentProduct={currentProduct}
            handleClose={this.handleClickClose}
          />
          {hasEditAccess && (
            <Fab
              color="primary"
              aria-label="Ajouter une prestation"
              className={classes.fab}
              onClick={this.handleClickOpen}
            >
              <AddIcon />
            </Fab>
          )}
        </Grid>
      </Fragment>
    );
  }
}

const styles = theme => ({
  actionsContainer: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
    marginBottom: 80
  },
  table: {
    minWidth: 700
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`
  },
  loadingCell: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  woMarginTop: {
    marginTop: 0
  },
  advancedContainer: {
    marginTop: theme.spacing(3)
  }
});

export default withRouter(withWidth()(withSnackbar(withStyles(styles)(ProductListing))));
