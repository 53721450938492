import React, { Component, Fragment } from "react";
import { Grid, Paper, withStyles, CircularProgress, Typography, Button, Tabs, Tab } from "@material-ui/core";
import { PieChart, Pie, ResponsiveContainer, Legend } from "recharts";
import withWidth from "@material-ui/core/withWidth";
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import InvoicesList from "./InvoicesList";
import DashboardService from "../../../services/DashboardService";
import InvoicesWidgets from "./InvoicesWidgets";
import { formatCurrency } from "../../../utils/numberFormat";
import { INVOICE_STATUS } from "../../../utils/status";
import { sortInvoiceByAttribute } from "../../../utils/app";

@inject("sessionStore")
@observer
class DashboardInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      invoices: [],
      invoicesData: undefined,
      invoicesCount: 0,
      turnover: 0,
      turnoverWithoutVates: 0,
      selectedInvoicesType: "INVOICES" // INVOICES | DRAFT
    };
  }

  componentDidMount() {
    this.getDashboardData();
  }

  componentDidUpdate(prevProps) {
    const { from, to } = this.props;
    if (prevProps.from !== from || prevProps.to !== to) {
      this.getDashboardData();
    }
  }

  getDashboardData = () => {
    const { from, to, clientId } = this.props;
    this.setState({ loading: true });
    DashboardService.dashboardInvoices(from.format("YYYY-MM-DD"), to.format("YYYY-MM-DD"), clientId)
      .then(resp => {
        const { invoices, invoicesData, invoicesCount } = resp;
        let turnover = 0;
        let turnoverWithoutVates = 0;
        const graphData = [];
        Object.keys(invoicesData).forEach(status => {
          if (status !== "DRAFT") {
            turnoverWithoutVates += Number.parseFloat(invoicesData[status].amountWithoutTVA);
            turnover += Number.parseFloat(invoicesData[status].amount);
            if (invoicesData[status].amountWithoutTVA > 0) {
              graphData.push({
                name: INVOICE_STATUS[status].labelPlural,
                value: invoicesData[status].amountWithoutTVA,
                fill: INVOICE_STATUS[status].color
              });
            }
          }
        });
        this.setState({
          invoices,
          invoicesData,
          turnover,
          graphData,
          turnoverWithoutVates,
          invoicesCount: invoicesCount - invoicesData.DRAFT.count
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  canPreviewInvoices = () => {
    const { sessionStore } = this.props;
    return sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_READ", "INVOICE_MY"],
      aclType: "oneof",
      requiredFeatures: ["INVOICE"]
    });
  };

  handlePreviewInvoices = () => {
    const { history, from, to } = this.props;
    const searchParams = new URLSearchParams({
      from: from.format("YYYY-MM-DD"),
      to: to.format("YYYY-MM-DD")
    });
    history.push({
      pathname: "/factures",
      hash: "",
      search: searchParams.toString()
    });
  };

  handlePreviewDraftInvoices = () => {
    const { history, from, to } = this.props;
    const searchParams = new URLSearchParams({
      from: from.format("YYYY-MM-DD"),
      to: to.format("YYYY-MM-DD")
    });
    history.push({
      pathname: "/factures",
      hash: "#brouillons",
      search: searchParams.toString()
    });
  };

  render() {
    const {
      loading,
      graphData,
      invoices,
      invoicesData,
      turnover,
      turnoverWithoutVates,
      invoicesCount,
      selectedInvoicesType
    } = this.state;
    const { classes, clientId } = this.props;

    const splittedInvoices = {
      DRAFT: invoices.filter(invoice => invoice.status === "DRAFT"),
      INVOICES: invoices.filter(invoice => invoice.status !== "DRAFT")
    };

    return loading ? (
      <div className={classes.centerizedLoader}>
        <CircularProgress size={80} />
      </div>
    ) : (
      <Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className={classes.graphAndTurnover}>
            <div className={classes.root}>
              <Grid container>
                <div className={classes.turnover}>
                  <div style={{ flex: 2 }}>
                    <Typography variant="body1" className={classes.turnoverTitle}>
                      Facture{invoicesCount > 1 && "s"}
                    </Typography>
                    <Typography variant="h5">{invoicesCount}</Typography>
                  </div>
                  <div style={{ flex: 2 }}>
                    <Typography variant="body1" className={classes.turnoverTitle}>
                      Chiffre d&apos;affaires
                    </Typography>
                    <Typography variant="h5">
                      {formatCurrency(turnoverWithoutVates)}{" "}
                      {turnover > 0 && (
                        <span style={{ display: "block", fontSize: "1.2rem" }}>({formatCurrency(turnover)} TTC)</span>
                      )}
                    </Typography>
                  </div>
                </div>
                <Grid item xs={12} className={classes.graphContainer}>
                  {turnover > 0 ? (
                    <ResponsiveContainer width="100%" height={300}>
                      <PieChart>
                        <Legend wrapperStyle={{ marginTop: 10 }} />
                        <Pie
                          legendType="none"
                          isAnimationActive={false}
                          data={graphData}
                          dataKey="value"
                          innerRadius={0}
                          outerRadius={90}
                          label={data => formatCurrency(data.payload.value)}
                        />
                      </PieChart>
                    </ResponsiveContainer>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 200
                      }}
                    >
                      <Typography variant="body2">Aucune donnée à afficher</Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {invoicesData && (
            <Grid item xs={12} md={6} className={classes.widgets}>
              <div className={classes.root}>
                <InvoicesWidgets invoicesData={invoicesData} />
              </div>
            </Grid>
          )}
        </Grid>

        {this.canPreviewInvoices() && (
          <div className={classes.root}>
            <Grid container spacing={3}>
              <Grid item xs={12} align="center">
                <Tabs
                  value={selectedInvoicesType}
                  onChange={(e, selectedTab) => {
                    this.setState({ selectedInvoicesType: selectedTab });
                  }}
                  variant="fullWidth"
                  className={classes.tabscontainer}
                >
                  <Tab value="INVOICES" label="Factures" className={classes.tab} />
                  <Tab value="DRAFT" label="Brouillons" className={classes.tab} />
                </Tabs>
              </Grid>
              <Grid item xs={12}>
                <Paper className={classes.table}>
                  {selectedInvoicesType === "INVOICES" && (
                    <InvoicesList invoices={splittedInvoices.INVOICES} hideContactColumn={!!clientId} />
                  )}
                  {selectedInvoicesType === "DRAFT" && (
                    <InvoicesList
                      invoices={sortInvoiceByAttribute(splittedInvoices.DRAFT, "createAt")}
                      hideContactColumn={!!clientId}
                      view="draft"
                    />
                  )}
                </Paper>
              </Grid>
              {invoices.length > 0 && selectedInvoicesType === "INVOICES" && splittedInvoices.INVOICES.length > 0 && (
                <Grid item xs={12} align="center">
                  <Button variant="contained" color="primary" onClick={this.handlePreviewInvoices}>
                    Voir {splittedInvoices.INVOICES.length > 1 ? "les factures" : "la facture"}
                  </Button>
                </Grid>
              )}
              {invoices.length > 0 && selectedInvoicesType === "DRAFT" && splittedInvoices.DRAFT.length > 0 && (
                <Grid item xs={12} align="center">
                  <Button variant="contained" color="primary" onClick={this.handlePreviewDraftInvoices}>
                    Voir {splittedInvoices.DRAFT.length > 1 ? "les brouillons" : "le brouillon"}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </Fragment>
    );
  }
}

const styles = theme => ({
  head: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2)
  },
  table: {
    overflowX: "auto"
  },
  title: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing()
  },
  centerizedLoader: {
    display: "flex",
    minHeight: 100,
    paddingTop: theme.spacing(2),
    justifyContent: "center",
    alignItems: "center"
  },
  graphContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  turnover: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: theme.spacing(3),
    width: "100%",
    textAlign: "center"
  },
  turnoverTitle: {
    marginBottom: theme.spacing()
  },
  graphAndTurnover: {
    order: 1,
    paddingLeft: 0,
    paddingRight: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      order: 2
    }
  },
  widgets: {
    order: 2,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      order: 1
    }
  }
});

export default withRouter(withWidth()(withStyles(styles)(DashboardInvoices)));
