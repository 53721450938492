import { inject, observer } from "mobx-react";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, TextField } from "@material-ui/core";
import DepositValue from "./DepositValue";
import { formatCurrency } from "../../../utils/numberFormat";

@inject("newOrderFormStore")
@observer
class DepositForm extends React.Component {
  render() {
    const { newOrderFormStore } = this.props;
    const { cashFormDeposits } = newOrderFormStore;

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {cashFormDeposits.map((deposit, index) => (
          <Grid key={`deposit-${index + 1}`} container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="label"
                placeholder="Acompte"
                label="Libellé de l'acompte"
                fullWidth
                variant="outlined"
                value={deposit.label}
                size="small"
                onChange={newOrderFormStore.updateCashFormDeposit(index)}
                helperText={
                  deposit.amount
                    ? `Sera affiché comme "${deposit.label || "Acompte"}${
                        deposit.isPercentage ? ` (${deposit.inputValue}%)` : ""
                      } : ${formatCurrency(deposit.amount, "EUR")}"`
                    : "Ne sera pas affiché"
                }
              />
            </Grid>
            <Grid item xs={5}>
              <DepositValue deposit={deposit} handleChange={newOrderFormStore.updateCashFormDeposit(index)} />
            </Grid>
            <Grid item xs={1}>
              <Button
                size="small"
                color="primary"
                onClick={() => newOrderFormStore.removeCashFormDeposit(index)}
                style={{ marginTop: 4, marginBottom: 4 }}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        ))}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button color="primary" variant="outlined" onClick={newOrderFormStore.addCashFormDeposit}>
            Ajouter un acompte
          </Button>
        </div>
      </div>
    );
  }
}

export default DepositForm;
