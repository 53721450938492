import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";

export default function PreviewDocumentTemplateDialog({ open, onClose, template }) {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Prévisualisation du template {template ? template.name : ""}</DialogTitle>
      <DialogContent>
        <img
          style={{ width: "calc(100% - 20px)", height: "auto", border: "1px solid #ccc" }}
          src={`${process.env.API_URL}/public/documentTemplates/${template ? template.path : ""}`}
          alt={template ? template.name : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
}
