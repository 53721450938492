import React, { useEffect, useState } from "react";
import { Grid, Stepper, Step, StepLabel, Paper, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import * as yup from "yup";
import { withRouter } from "react-router";
import { useSnackbar } from "notistack";
import moment from "moment";

import CompanyDetailsForm from "./company/CompanyDetailsForm";
import CompanyFeaturesForm from "./company/CompanyFeaturesForm";
import { FEATURES } from "../../utils/matrix";
import CompanyFormButtons from "./company/CompanyFormButtons";
import ValidatorService from "../../services/ValidatorService";
import AdminService from "../../services/AdminService";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  btnWrapper: {
    textAlign: "right"
  },
  prevBtn: {
    marginRight: theme.spacing(2)
  },
  formContainer: {
    marginTop: theme.spacing()
  },
  loader: {
    textAlign: "center",
    marginTop: theme.spacing(4)
  }
}));

const CompanySchema = yup.object({
  name: yup.string().required("Champ requis"),
  status: yup.string().required("Champ requis"),
  siret: yup
    .string()
    .test("siret", "Format invalide", val => (val ? val.length === 14 : false))
    .required("Champ requis"),
  tvaNumber: yup.string().required("Champ requis"),
  rcs: yup.string().required("Champ requis"),
  capital: yup.string().required("Champ requis"),
  paymentDelay: yup
    .number()
    .min(0, "Ne peut être négatif")
    .required("Champ requis"),
  paymentTerms: yup.string().required("Champ requis"),
  paymentPenalty: yup.string().required("Champ requis"),
  address: yup.string().required("Champ requis"),
  zipCode: yup.string().required("Champ requis"),
  city: yup.string().required("Champ requis"),
  email: yup
    .string()
    .email("Email invalide")
    .required("Champ requis"),
  phone: yup
    .mixed()
    .test("check-phone", "Format invalide", value => (value ? ValidatorService.validatePhone(value) : true)),
  plan: yup.number().required("Champ requis"),
  signatures: yup.number().required("Champ requis")
});

const DEFAULT_COMPANY = {
  name: "",
  status: "",
  rcs: "",
  capital: "",
  paymentDelay: 60,
  paymentPenalty:
    "Pénalités de retard sans rappel préalable : taux BCE à son opération de refinancement la plus récente majoré de 10 %. Indemnité forfaitaire pour frais de recouvrement : 40 €. Décret n° 2012-1115 du 2 octobre 2012.",
  paymentTerms: "Mode de paiement : chèque, virement, espèces...",
  siret: "",
  tvaNumber: "",
  address: "",
  zipCode: "",
  city: "",
  phone: "",
  email: "",
  customColor: false,
  plan: 0,
  signatures: 0,
  features: FEATURES.filter(f => f.isRootFeature === true).map(f => f.key),
  nextInvoiceDate: moment().add(1, "months"),
  signatureProvider: "YOUSIGN"
};

function CompanyForm({ companyId, history }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState();
  const [isFetchingCompany, setIsFetchingCompany] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const steps = ["Informations générales", "Fonctionnalités"];

  const loadCompanyIfExists = () => {
    setIsFetchingCompany(true);
    if (companyId) {
      AdminService.getCompany(companyId)
        .then(res => {
          setInitialValues(res.content);
          setIsFetchingCompany(false);
        })
        .catch(() => {
          enqueueSnackbar("Une erreur est survenue lors de la récupération de l'entreprise", { variant: "error" });
        });
    } else {
      setInitialValues(DEFAULT_COMPANY);
      setIsFetchingCompany(false);
    }
  };

  useEffect(() => {
    loadCompanyIfExists();
  }, []);

  const handleSubmit = (values, actions) => {
    const promise = companyId ? AdminService.patchCompany(values) : AdminService.addCompany(values);
    promise
      .then(res => {
        actions.setSubmitting(false);
        history.push(`/superbg/entreprises/details/${companyId || res.newCompany}`);
        enqueueSnackbar(companyId ? "L'entreprise a été modifiée" : "L'entreprise a été créée", { variant: "success" });
      })
      .catch(error => {
        if (error.status === 400) {
          enqueueSnackbar("Une erreur est survenue lors de l'enregistrement de l'entreprise, vérifiez les données", {
            variant: "error"
          });
        } else if (error.status === 409) {
          enqueueSnackbar("Une entreprise similaire existe déjà", {
            variant: "info"
          });
        } else {
          enqueueSnackbar("Une erreur interne est survenue lors de l'enregistrement de l'entreprise", {
            variant: "error"
          });
        }
        actions.setSubmitting(false);
      });
  };

  if (!initialValues || isFetchingCompany) {
    return <CircularProgress />;
  }
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h4">Informations de l&apos;entreprise</Typography>
      </Grid>
      {initialValues ? (
        <Grid item xs={12}>
          <Formik
            initialValues={{ ...DEFAULT_COMPANY, ...initialValues }}
            validationSchema={CompanySchema}
            onSubmit={handleSubmit}
          >
            <Paper className={classes.formContainer}>
              <Grid container>
                <Grid item xs={8} style={{ textAlign: "center" }}>
                  <Stepper activeStep={activeStep}>
                    {steps.map(label => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
                <Grid item xs={12}>
                  <form>
                    {activeStep === 0 && <CompanyDetailsForm />}
                    {activeStep === 1 && <CompanyFeaturesForm />}
                  </form>
                </Grid>
                <CompanyFormButtons activeStep={activeStep} setActiveStep={setActiveStep} steps={steps.length} />
              </Grid>
            </Paper>
          </Formik>
        </Grid>
      ) : (
        <Grid container>
          <Grid item xs={12} className={classes.loader}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default withRouter(CompanyForm);
