import React, { useState } from "react";
import { Fab, Grid, Paper, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/styles";

import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";
import TemplatesList from "../../components/admin/templates/TemplatesList";
import AddDocumentTemplateDialog from "../../components/admin/templates/AddDocumentTemplateDialog";

const useStyles = makeStyles(theme => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  container: { padding: theme.spacing(2) },
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  }
}));

function SuperAdminTemplatesPage({ match }) {
  const [addDocumentTemplateDialogOpen, setAddDocumentTemplateDialogOpen] = useState(false);
  const classes = useStyles();

  const handleClickOpen = () => {
    setAddDocumentTemplateDialogOpen(true);
  };

  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Liste des templates</Typography>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.root}>
            <TemplatesList />
          </Paper>
        </Grid>
      </Grid>
      <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleClickOpen}>
        <AddIcon />
      </Fab>
      <AddDocumentTemplateDialog
        open={addDocumentTemplateDialogOpen}
        onClose={() => setAddDocumentTemplateDialogOpen(false)}
      />
    </div>
  );
}

export default SuperAdminTemplatesPage;
