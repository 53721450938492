import React from "react";
import AssignmentIcon from "@material-ui/icons/Assignment";
import BusinessIcon from "@material-ui/icons/Business";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import WorkIcon from "@material-ui/icons/Work";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { Grid, MenuItem, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useFormikContext } from "formik";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

export default function CompanyDetailsForm() {
  const { errors, handleChange, touched, values, handleBlur, setFieldValue } = useFormikContext();

  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center}>
            <BusinessIcon />
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              name="status"
              placeholder="EURL"
              label="Forme de la société"
              margin="normal"
              variant="outlined"
              fullWidth
              value={values.status || ""}
              onChange={handleChange}
              error={Boolean(touched.status && errors.status)}
              helperText={errors.status}
              onBlur={e => {
                if (!touched.status) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="name"
              placeholder="KingKang"
              label="Raison sociale"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.name || ""}
              onChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              helperText={errors.name}
              onBlur={e => {
                if (!touched.name) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center}>
            <WorkIcon />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="siret"
              placeholder="802 954 785 00028"
              label="Siret"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              inputProps={{ maxLength: 14 }}
              value={values.siret || ""}
              onChange={handleChange}
              error={Boolean(touched.siret && errors.siret)}
              helperText={errors.siret}
              onBlur={e => {
                if (!touched.siret) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="tvaNumber"
              placeholder="83 404 833 048"
              label="Numéro de TVA"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.tvaNumber || ""}
              onChange={handleChange}
              error={Boolean(touched.tvaNumber && errors.tvaNumber)}
              helperText={errors.tvaNumber}
              onBlur={e => {
                if (!touched.tvaNumber) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center} />
          <Grid item xs={5}>
            <TextField
              required
              name="rcs"
              placeholder="RCS"
              label="RCS"
              variant="outlined"
              margin="normal"
              fullWidth
              value={values.rcs || ""}
              onChange={handleChange}
              error={Boolean(touched.rcs && errors.rcs)}
              helperText={errors.rcs}
              onBlur={e => {
                if (!touched.rcs) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              name="capital"
              placeholder="Capital"
              label="Capital"
              variant="outlined"
              margin="normal"
              fullWidth
              value={values.capital || ""}
              onChange={handleChange}
              error={Boolean(touched.capital && errors.capital)}
              helperText={errors.capital}
              onBlur={e => {
                if (!touched.capital) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center} />
          <Grid item xs={5}>
            <TextField
              name="paymentDelay"
              variant="outlined"
              type="number"
              inputProps={{ min: "1", step: "1" }}
              value={values.paymentDelay || "0"}
              label="Délais de paiement"
              placeholder="60"
              fullWidth
              margin="normal"
              required
              onChange={handleChange}
              error={Boolean(touched.paymentDelay && errors.paymentDelay)}
              helperText={errors.paymentDelay}
              onBlur={e => {
                if (!touched.paymentDelay) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              required
              name="paymentTerms"
              placeholder="Mode de paiement : chèque, virement, espèces..."
              label="Modalités de paiement"
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
              rows={2}
              value={values.paymentTerms || ""}
              onChange={handleChange}
              error={Boolean(touched.paymentTerms && errors.paymentTerms)}
              helperText={errors.paymentTerms}
              onBlur={e => {
                if (!touched.paymentTerms) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={1} className={classes.center} />
          <Grid item xs={1} className={classes.center} />

          <Grid item xs={10}>
            <TextField
              required
              name="paymentPenalty"
              placeholder="Pénalités de retard sans rappel préalable : taux BCE à son opération de refinancement la plus récente majoré de 10 %. Indemnité forfaitaire pour frais de recouvrement : 40 €. Décret n° 2012-1115 du 2 octobre 2012."
              label="Pénalité de paiement"
              variant="outlined"
              margin="normal"
              fullWidth
              value={values.paymentPenalty || ""}
              onChange={handleChange}
              error={Boolean(touched.paymentPenalty && errors.paymentPenalty)}
              helperText={errors.paymentPenalty}
              onBlur={e => {
                if (!touched.paymentPenalty) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center}>
            <LocationOnIcon />
          </Grid>
          <Grid item xs={4}>
            <TextField
              name="address"
              placeholder="Rue Jeanne d'Arc"
              label="Adresse"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.address || ""}
              onChange={handleChange}
              error={Boolean(touched.address && errors.address)}
              helperText={errors.address}
              onBlur={e => {
                if (!touched.address) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="zipCode"
              placeholder="76000"
              label="Code postal"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.zipCode || ""}
              onChange={handleChange}
              error={Boolean(touched.zipCode && errors.zipCode)}
              helperText={errors.zipCode}
              onBlur={e => {
                if (!touched.zipCode) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="city"
              placeholder="Rouen"
              label="Ville"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.city || ""}
              onChange={handleChange}
              error={Boolean(touched.city && errors.city)}
              helperText={errors.city}
              onBlur={e => {
                if (!touched.city) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center}>
            <MailIcon />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="email"
              placeholder="entreprise@mail.com"
              label="E-mail"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.email || ""}
              onChange={handleChange}
              type="email"
              error={Boolean(touched.email && errors.email)}
              helperText={errors.email}
              onBlur={e => {
                if (!touched.email) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="phone"
              placeholder="0610203040"
              label="Téléphone"
              margin="normal"
              variant="outlined"
              fullWidth
              required
              value={values.phone || ""}
              onChange={handleChange}
              type="phone"
              error={Boolean(touched.phone && errors.phone)}
              helperText={errors.phone}
              onBlur={e => {
                if (!touched.phone) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={1} className={classes.center}>
            <AssignmentIcon />
          </Grid>
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              select
              className={classes.advancedInput}
              id="signatureProvider"
              name="signatureProvider"
              type="text"
              placeholder="Fournisseur"
              label="Fournisseur"
              value={values.signatureProvider}
              margin="normal"
              fullWidth
              onChange={handleChange}
            >
              <MenuItem key="YOUSIGN" value="YOUSIGN">
                YOUSIGN
              </MenuItem>
              <MenuItem key="CONTRALIA" value="CONTRALIA">
                CONTRALIA
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="plan"
              placeholder="50"
              label="Forfait : Nombre de signatures / mois"
              margin="normal"
              variant="outlined"
              required
              fullWidth
              value={values.plan}
              onChange={handleChange}
              type="number"
              error={Boolean(touched.plan && errors.plan)}
              helperText={errors.plan}
              onBlur={e => {
                if (!touched.plan) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="signatures"
              placeholder="50"
              label="Nombre de signatures disponibles"
              margin="normal"
              variant="outlined"
              required
              fullWidth
              value={values.signatures}
              onChange={handleChange}
              type="number"
              error={Boolean(touched.signatures && errors.signatures)}
              helperText={errors.signatures}
              onBlur={e => {
                if (!touched.signatures) {
                  handleBlur(e);
                }
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={1} className={classes.center}>
              <AttachMoneyIcon />
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="brandStructure"
                placeholder="XX"
                label="Sofinco - Code enseigne"
                margin="normal"
                variant="outlined"
                fullWidth
                value={values.brandStructure || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                name="equipmentCode"
                placeholder="29A"
                label="Sofinco - Nature du bien"
                margin="normal"
                variant="outlined"
                fullWidth
                value={values.equipmentCode || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1} className={classes.center} />
              <Grid item xs={5}>
                <TextField
                  name="franfinanceToken"
                  placeholder="xxxxxxxxx"
                  label="Franfinance - Chaine d'authentification"
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  value={values.franfinanceToken || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={1} className={classes.center} />
              <Grid item xs={5}>
                <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                  <DatePicker
                    className={classes.advancedInput}
                    id="nextInvoiceDateFrom"
                    cancelLabel="Annuler"
                    clearLabel="Réinitialiser"
                    clearable
                    format="D MMM YYYY"
                    fullWidth
                    label="Date de facturation"
                    inputVariant="outlined"
                    margin="normal"
                    leftArrowIcon={<KeyboardArrowLeftIcon />}
                    rightArrowIcon={<KeyboardArrowRightIcon />}
                    value={values.nextInvoiceDate}
                    onChange={date => setFieldValue("nextInvoiceDate", date)}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
