import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import BugReportRoundedIcon from "@material-ui/icons/BugReportRounded";
import BusinessRoundedIcon from "@material-ui/icons/BusinessRounded";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";

import AdminBreadcrumb from "../../components/admin/AdminBreadcrumb";
import { superAdmin } from "../../routes";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C"
  }
}));

export default function SuperAdminHomePage({ history, match }) {
  const classes = useStyles();

  return (
    <div>
      <AdminBreadcrumb routes={superAdmin} match={match} />
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Accueil BG</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card
            className={classes.card}
            variant="outlined"
            onClick={() => {
              history.push({ pathname: "/superbg/entreprises" });
            }}
          >
            <BusinessRoundedIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Entreprises</Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card
            className={classes.card}
            variant="outlined"
            onClick={() => {
              history.push({ pathname: "/superbg/utilisateurs" });
            }}
          >
            <GroupRoundedIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Utilisateurs</Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card
            className={classes.card}
            variant="outlined"
            onClick={() => {
              history.push({ pathname: "/superbg/templates" });
            }}
          >
            <PhotoAlbumIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Templates</Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card
            className={classes.card}
            variant="outlined"
            onClick={() => {
              history.push({ pathname: "/superbg/logs" });
            }}
          >
            <BugReportRoundedIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Logs</Typography>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
