import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import AdminService from "../../../services/AdminService";

export default function AddDocumentTemplateDialog({ open, onClose }) {
  const [templateName, setTemplateName] = useState("");
  const [file, setFile] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const handleFileUpload = e => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    const _formData = new FormData();
    _formData.append("template", file);
    _formData.append("name", templateName);

    AdminService.addDocumentTemplate(_formData)
      .then(() => {
        enqueueSnackbar("Template créé avec succès", { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["document_templates"] });
        onClose();
      })
      .catch(err => {
        console.error(err);
        enqueueSnackbar("Erreur lors de la création du template", { variant: "error" });
      });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Créer un template</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label="Nom du template"
              value={templateName}
              onChange={e => setTemplateName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="uploaded-file"
              type="file"
              onChange={handleFileUpload}
            />
            <label htmlFor="uploaded-file">
              <Button
                fullWidth
                variant={file ? "outlined" : "contained"}
                color="primary"
                component="span"
                style={{ textAlign: "center" }}
              >
                {file ? `Remplacer ${file.name}` : "Importer un fichier"}
              </Button>
            </label>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={!templateName || !file}>
          Enregistrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
