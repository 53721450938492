import React, { useState } from "react";
import {
  MenuItem,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Typography
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";

import { useQuery, useQueryClient } from "@tanstack/react-query";
// import AdminService from "../../../services/AdminService";
import TableAction from "../../TableAction";
import CompanyService from "../../../services/CompanyService";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../../dialog/ConfirmationDialog";
import AdminService from "../../../services/AdminService";
import PreviewDocumentTemplateDialog from "./PreviewDocumentTemplateDialog";

const useStyles = makeStyles(theme => ({
  container: { padding: theme.spacing(2) },
  root: { width: "100%", overflowX: "auto" },
  table: { minWidth: 700 },
  row: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#F7F7F7"
    }
  }
}));

function TemplatesList() {
  const [focusedTemplate, setFocusedTemplate] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery({
    queryKey: ["document_templates"],
    queryFn: () => CompanyService.getDocumentTemplates()
  });

  const classes = useStyles();

  const handleDeletion = async templateId => {
    AdminService.deleteDocumentTemplate(templateId)
      .then(() => {
        enqueueSnackbar("Template supprimé avec succès", { variant: "success" });
        queryClient.invalidateQueries({ queryKey: ["document_templates"] });
      })
      .catch(() => {
        enqueueSnackbar("Erreur lors de la suppression du template", { variant: "error" });
      });
  };

  return (
    <Grid container>
      {isLoading ? (
        <Grid item xs={12} style={{ textAlign: "center", padding: 20 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid item xs={12}>
          {data && data.content.length > 0 ? (
            <Paper className={classes.root}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Utilisé par</TableCell>
                    <TableCell style={{ width: 50 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data && !isLoading ? (
                    data.content.map(template => (
                      <TableRow
                        key={template.id}
                        selected={template.id === focusedTemplate}
                        className={classes.row}
                        onClick={() => setFocusedTemplate(template)}
                      >
                        <TableCell>{template.name}</TableCell>
                        <TableCell>
                          {template.companies.length === 0 ? (
                            <Typography variant="body2" color="textSecondary">
                              Aucune entreprise
                            </Typography>
                          ) : (
                            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                              {template.companies.map(company => company.name).join("\n")}
                            </Typography>
                          )}
                        </TableCell>

                        <TableCell>
                          <TableAction>
                            <ConfirmationDialog>
                              {confirmBeforeAct => (
                                <MenuItem
                                  onClick={e =>
                                    stopPropagationForConfirmation(
                                      e,
                                      confirmBeforeAct(() => handleDeletion(template.id))
                                    )
                                  }
                                >
                                  Supprimer
                                </MenuItem>
                              )}
                            </ConfirmationDialog>
                          </TableAction>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="3" align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <PreviewDocumentTemplateDialog
                open={focusedTemplate}
                onClose={() => setFocusedTemplate(null)}
                template={focusedTemplate}
              />
            </Paper>
          ) : (
            <div style={{ textAlign: "center", padding: 20 }}>Vous n&apos;avez pas encore créé de template</div>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default TemplatesList;
