import React from "react";
import AssignmentTurnedIn from "@material-ui/icons/AssignmentTurnedInOutlined";
import MarkunreadMailboxOutlinedIcon from "@material-ui/icons/MarkunreadMailboxOutlined";

import EuroIcon from "@material-ui/icons/Euro";
import ArchiveIcon from "@material-ui/icons/Archive";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { Grid } from "@material-ui/core";

import Widget from "../Widget";
import { formatCurrency } from "../../../utils/numberFormat";
import { INVOICE_STATUS } from "../../../utils/status";

function InvoicesWidgets({ invoicesData }) {
  const data = [
    {
      title: INVOICE_STATUS.DRAFT.dashboardLabel,
      count: invoicesData.DRAFT ? invoicesData.DRAFT.count : 0,
      amount: formatCurrency(invoicesData.DRAFT.amountWithoutTVA),
      color: INVOICE_STATUS.DRAFT.color,
      icon: <ScheduleIcon fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: INVOICE_STATUS.SENT.dashboardLabel,
      count: invoicesData.SENT ? invoicesData.SENT.count : 0,
      amount: formatCurrency(invoicesData.SENT.amountWithoutTVA),
      color: INVOICE_STATUS.SENT.color,
      icon: <MarkunreadMailboxOutlinedIcon fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: INVOICE_STATUS.VALIDATED.dashboardLabel,
      count: invoicesData.VALIDATED ? invoicesData.VALIDATED.count : 0,
      amount: formatCurrency(invoicesData.VALIDATED.amountWithoutTVA),
      color: INVOICE_STATUS.VALIDATED.color,
      icon: <AssignmentTurnedIn fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: INVOICE_STATUS.PAID.dashboardLabel,
      count: invoicesData.PAID ? invoicesData.PAID.count : 0,
      amount: formatCurrency(invoicesData.PAID.amountWithoutTVA),
      color: INVOICE_STATUS.PAID.color,
      icon: <EuroIcon fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: INVOICE_STATUS.CREDIT_NOTE.dashboardLabel,
      count: invoicesData.CREDIT_NOTE ? invoicesData.CREDIT_NOTE.count : 0,
      amount: formatCurrency(invoicesData.CREDIT_NOTE.amountWithoutTVA),
      color: INVOICE_STATUS.CREDIT_NOTE.color,
      icon: <ArchiveIcon fontSize="large" style={{ color: "#FFF" }} />
    },
    {
      title: INVOICE_STATUS.CANCEL.dashboardLabel,
      count: invoicesData.CANCEL ? invoicesData.CANCEL.count : 0,
      amount: formatCurrency(invoicesData.CANCEL.amountWithoutTVA),
      color: INVOICE_STATUS.CANCEL.color,
      icon: <CancelPresentationIcon fontSize="large" style={{ color: "#FFF" }} />
    }
  ];

  return (
    <Grid container spacing={3}>
      {data.map(element => (
        <Grid item xs={12} sm={6} key={element.title}>
          <Widget
            title={element.title}
            amount={element.amount}
            count={element.count}
            color={element.color}
            icon={element.icon}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default InvoicesWidgets;
