import React, { Component } from "react";
import ReactDOM from "react-dom";
import {
  Card,
  CardHeader,
  Tooltip,
  IconButton,
  CardContent,
  Grid,
  Paper,
  TextField,
  FormControl,
  InputAdornment,
  MenuItem,
  Switch,
  withStyles,
  Button,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Typography
} from "@material-ui/core";
import Downshift from "downshift";
import DeleteIcon from "@material-ui/icons/Delete";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { inject, observer } from "mobx-react";
import Decimal from "decimal.js-light";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { TVAS_DROPDOWN } from "../../classes/Product";
import { formatCurrency } from "../../utils/numberFormat";
import DrawingTable from "../drawingTable";
import ConfirmationDialog, { stopPropagationForConfirmation } from "../dialog/ConfirmationDialog";

function renderInput({ InputProps, classes, ref, ...other }) {
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        ...InputProps
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = selectedItem ? (selectedItem.title || "").indexOf(suggestion.title) > -1 : false;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.id}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400
      }}
    >
      {suggestion.category ? <span style={{ fontWeight: "bold" }}>[{suggestion.category.name}]&nbsp;</span> : ""}
      {suggestion.title}
      {suggestion.reference ? (
        <span style={{ fontSize: "80%", fontStyle: "italic" }}>&nbsp;(Référence : {suggestion.reference})</span>
      ) : (
        ""
      )}
    </MenuItem>
  );
}

@inject("newInvoiceStore", "sessionStore")
@observer
class InvoiceProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDrawingTable: false,
      virtualDiscountAmount: 0,
      virtualDiscountType: "NUMERIC",
      wasUpdated: false
    };
  }

  componentDidMount() {
    const { detail } = this.props;
    this.setState({ virtualDiscountAmount: detail.discount || 0 });
  }

  componentDidUpdate(prevProps) {
    const { detail } = this.props;
    if (detail.discountType !== prevProps.detail.discountType) {
      this.updateDetailDiscount();
    }
  }

  getSuggestions(inputValue) {
    const { suggestions } = this.props;
    return suggestions
      .filter(suggestion => {
        const input = inputValue.toLowerCase();
        return (
          suggestion.title.toLowerCase().includes(input) ||
          (suggestion.reference && suggestion.reference.toLowerCase().includes(input)) ||
          (suggestion.category && suggestion.category.name.toLowerCase().includes(input))
        );
      })
      .slice(0, 25);
  }

  onSchemaSave = base64 => {
    const { index, newInvoiceStore } = this.props;
    newInvoiceStore.saveDrawing({ index, drawing: base64 });
    this.setState({ showDrawingTable: false });
  };

  handleDrawingButton = () => {
    const {
      detail: { drawing }
    } = this.props;
    if (drawing === null) {
      this.setState({ showDrawingTable: true });
    } else {
      const { newInvoiceStore, index } = this.props;
      newInvoiceStore.saveDrawing({ drawing: null, index });
    }
  };

  updateDetailDiscount = () => {
    const { newInvoiceStore, index, detail } = this.props;
    const { virtualDiscountAmount, virtualDiscountType } = this.state;
    const totalHT = new Decimal(Number(detail.price))
      .mul(Number(detail.quantity))
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
    const totalTTC = new Decimal(Number(detail.price))
      .mul(Number(detail.quantity))
      .mul((100 + Number(detail.tva)) / 100)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber()
      .toFixed(2);
    if (virtualDiscountType === "PERCENTAGE") {
      const total = detail.discountType === "HT" ? totalHT : totalTTC;
      const finalDiscount = (virtualDiscountAmount * total) / 100;
      newInvoiceStore.handleChangeDetailDiscount(index, finalDiscount);
    } else {
      newInvoiceStore.handleChangeDetailDiscount(index, virtualDiscountAmount);
    }
  };

  handleChangeDiscountType = () => {
    const { virtualDiscountType } = this.state;
    this.setState({ virtualDiscountType: virtualDiscountType === "PERCENTAGE" ? "NUMERIC" : "PERCENTAGE" }, () => {
      this.updateDetailDiscount();
    });
  };

  handleChangeDiscountAmount = e => {
    this.setState({ virtualDiscountAmount: e.target.value }, () => {
      this.updateDetailDiscount();
    });
  };

  render() {
    const { canDraw, detail, index, newInvoiceStore, classes, sessionStore } = this.props;
    const { showDrawingTable, virtualDiscountAmount, virtualDiscountType, wasUpdated } = this.state;
    if (detail.price > 0 && !wasUpdated) {
      // Bidouille parce que trop bien MUI
      this.forceUpdate();
      this.setState({ wasUpdated: true });
    }
    return (
      <Card className={classes.detail}>
        <CardHeader
          title={`Prestation ${index + 1}`}
          action={
            <div>
              <Tooltip title="Remonter la prestation">
                <div style={{ display: "inline-flex" }}>
                  <IconButton
                    disabled={index === 0}
                    color="secondary"
                    className={classes.button}
                    aria-label="Up item"
                    onClick={() => newInvoiceStore.handleSwapDetails({ index, newIndex: index - 1 })}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title="Descendre la prestation">
                <div style={{ display: "inline-flex" }}>
                  <IconButton
                    disabled={newInvoiceStore.details.length === 1 || index === newInvoiceStore.details.length - 1}
                    color="secondary"
                    className={classes.button}
                    aria-label="Down item"
                    onClick={() => newInvoiceStore.handleSwapDetails({ index, newIndex: index + 1 })}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip title="Supprimer cette prestation">
                <div style={{ display: "inline-flex" }}>
                  <IconButton
                    color="secondary"
                    className={classes.button}
                    aria-label="Delete"
                    onClick={newInvoiceStore.handleRemoveDetail(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={10}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Downshift
                    onChange={selection => newInvoiceStore.handleChangeAutocomplete(index, selection)}
                    itemToString={item => (item ? item.title : "")}
                  >
                    {({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
                      <div style={{ position: "relative" }}>
                        {renderInput({
                          fullWidth: true,
                          classes,
                          variant: "outlined",
                          label: "Dénomination",
                          required: true,
                          InputProps: getInputProps({
                            placeholder: "Dénomination",
                            id: detail.id,
                            value: detail.title,
                            classes: {
                              notchedOutline: detail.description === "" ? classes.notchedOutline : null
                            },
                            name: "title",
                            onChange: newInvoiceStore.handleChangeDetails(index)
                          })
                        })}
                        {this.getSuggestions(inputValue).length > 0 && isOpen && (
                          <Paper square className={classes.paperAutosuggest}>
                            {this.getSuggestions(inputValue).map((suggestion, idx) =>
                              renderSuggestion({
                                suggestion,
                                index: idx,
                                itemProps: getItemProps({ item: suggestion }),
                                highlightedIndex,
                                selectedItem
                              })
                            )}
                          </Paper>
                        )}
                      </div>
                    )}
                  </Downshift>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    multiline
                    InputProps={{
                      classes: {
                        notchedOutline: detail.description === "" ? classes.notchedOutline : null
                      }
                    }}
                    name="description"
                    onChange={newInvoiceStore.handleChangeDetails(index)}
                    required
                    rows="4"
                    value={detail.description}
                    variant="outlined"
                  />
                </Grid>
                {detail.customFields && detail.customFields.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      {detail.customFields.map((customField, i) => (
                        <Grid item xs={12} sm={4} key={`custom-input-${i + 1}`}>
                          <TextField
                            name="customFieldValue"
                            label={customField.label}
                            variant="outlined"
                            required={customField.required}
                            fullWidth
                            InputProps={{
                              classes: {
                                notchedOutline:
                                  customField.required && customField.value === "" ? classes.notchedOutline : null
                              }
                            }}
                            value={customField.value}
                            onChange={newInvoiceStore.handleCustomFieldChange({
                              customFieldIndex: i,
                              index
                            })}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    inputProps={{
                      min: "0",
                      step: "0.1"
                    }}
                    name="price"
                    label="Prix unitaire HT"
                    InputProps={{
                      classes: {
                        notchedOutline: detail.price === "" ? classes.notchedOutline : null
                      }
                    }}
                    disabled={detail.id && detail.id.length === 36 && !sessionStore.user.canUpdateProductPrice}
                    onChange={newInvoiceStore.handleChangeDetails(index)}
                    required
                    type="number"
                    value={detail.price}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl aria-describedby="tva-helper-text" fullWidth>
                    <TextField
                      select
                      id="tva"
                      name="tva"
                      type="number"
                      variant="outlined"
                      label="Montant TVA"
                      required
                      value={detail.tva}
                      onChange={newInvoiceStore.handleChangeDetails(index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" style={{ marginRight: 16 }}>
                            %
                          </InputAdornment>
                        )
                      }}
                    >
                      {TVAS_DROPDOWN.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    InputProps={{
                      classes: {
                        notchedOutline: detail.quantity === "" ? classes.notchedOutline : null
                      }
                    }}
                    inputProps={{
                      min: "0",
                      step: "1"
                    }}
                    label="Quantité"
                    name="quantity"
                    onChange={newInvoiceStore.handleChangeDetails(index)}
                    required
                    type="number"
                    value={detail.quantity}
                    variant="outlined"
                  />
                </Grid>
                {detail.price > 0 &&
                  (sessionStore.user.canApplyDiscount ? (
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={8} xl={9}>
                          <FormControl fullWidth variant="outlined">
                            <InputLabel
                              htmlFor="discount"
                              ref={ref => {
                                // eslint-disable-next-line react/no-find-dom-node
                                this.labelDepositRef = ReactDOM.findDOMNode(ref);
                              }}
                            >
                              Remise
                            </InputLabel>
                            <OutlinedInput
                              id="discount"
                              name="discount"
                              type="number"
                              error={
                                (virtualDiscountType === "NUMERIC" &&
                                  virtualDiscountAmount > newInvoiceStore.getMaxDiscountForItem(index)) ||
                                virtualDiscountAmount < 0 ||
                                (virtualDiscountType === "PERCENTAGE" &&
                                  (virtualDiscountAmount > 100 || virtualDiscountAmount < 0))
                              }
                              value={virtualDiscountAmount}
                              onChange={this.handleChangeDiscountAmount}
                              inputProps={{ min: "0" }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <ToggleButtonGroup
                                    exclusive
                                    value={virtualDiscountType}
                                    onChange={this.handleChangeDiscountType}
                                    style={{ height: 56, marginRight: -14 }}
                                  >
                                    <ToggleButton style={{ width: 56, fontSize: 18 }} value="PERCENTAGE">
                                      %
                                    </ToggleButton>
                                    <ToggleButton style={{ width: 56, fontSize: 18 }} value="NUMERIC">
                                      €
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </InputAdornment>
                              }
                              labelWidth={this.labelDepositRef ? this.labelDepositRef.offsetWidth : 0}
                            />
                            {((virtualDiscountType === "NUMERIC" &&
                              virtualDiscountAmount > newInvoiceStore.getMaxDiscountForItem(index)) ||
                              virtualDiscountAmount < 0 ||
                              (virtualDiscountType === "PERCENTAGE" &&
                                (virtualDiscountAmount > 100 || virtualDiscountAmount < 0))) && (
                              <FormHelperText id="component-helper-text" error>
                                La remise ne peut être inférieure à 0 ou supérieure au total
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} xl={3}>
                          <div className={classes.switchContainer}>
                            <span>Remise HT</span>
                            <Switch
                              color="primary"
                              checked={detail.discountType === "TTC"}
                              onChange={newInvoiceStore.handleChangeDiscountType(index)}
                            />
                            <span>Remise TTC</span>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : virtualDiscountAmount !== 0 ? (
                    <Grid item xs={12}>
                      <Typography variant="body2" style={{ fontStyle: "italic" }}>
                        Remise de {formatCurrency(+detail.discount < 0 ? +detail.discount * -1 : +detail.discount)}{" "}
                        {detail.discountType}
                      </Typography>
                    </Grid>
                  ) : null)}
                <Grid item xs={12}>
                  {canDraw && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Grid item xs={12} sm={7} md={5}>
                        {detail.drawing === null ? (
                          <Button
                            className={classes.buttonAddDrawing}
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="large"
                            onClick={this.handleDrawingButton}
                          >
                            Ajouter schéma
                          </Button>
                        ) : (
                          <ConfirmationDialog situationText="Êtes-vous sûr de vouloir supprimer ce schéma ?">
                            {confirmBeforeAct => (
                              <Button
                                className={classes.buttonRemoveDrawing}
                                variant="outlined"
                                color="primary"
                                fullWidth
                                size="large"
                                onClick={e => {
                                  stopPropagationForConfirmation(
                                    e,
                                    confirmBeforeAct(() => this.handleDrawingButton())
                                  );
                                }}
                              >
                                Supprimer schéma
                              </Button>
                            )}
                          </ConfirmationDialog>
                        )}
                      </Grid>
                    </div>
                  )}

                  <DrawingTable
                    onSave={this.onSchemaSave}
                    backgroundImg={detail.drawing || null}
                    open={showDrawingTable}
                    handleClose={() => this.setState({ showDrawingTable: false })}
                    title={detail.title ? `Schéma ${detail.title}` : `Schéma Prestation ${index + 1}`}
                  />
                </Grid>
                {detail.drawing !== null && (
                  <Grid item xs={12} className={classes.drawingWrapper}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <img
                        style={{ width: "100%", maxWidth: 700, cursor: "pointer" }}
                        src={detail.drawing}
                        onClick={() => this.setState({ showDrawingTable: true })}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={2} container direction="column" alignItems="stretch" justify="space-evenly">
              <Grid item>
                <p className={classes.total}>Total</p>
              </Grid>
              <Grid item>
                <p className={classes.priceWithoutTVA}>
                  {formatCurrency(newInvoiceStore.priceWithoutTVA(detail), newInvoiceStore.currency)} HT
                </p>
              </Grid>
              <Grid item>
                <p className={classes.priceWithTVA}>
                  {formatCurrency(newInvoiceStore.priceWithTVA(detail), newInvoiceStore.currency)} TTC
                </p>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const styles = theme => ({
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    padding: theme.spacing()
  },
  total: {
    textAlign: "center",
    fontSize: theme.typography.fontSize * 2,
    fontWeight: theme.typography.fontWeightMedium,
    margin: 0
  },
  priceWithTVA: {
    textAlign: "center",
    fontSize: theme.typography.fontSize * 1.5,
    fontWeight: theme.typography.fontWeightRegular,
    margin: 0
  },
  priceWithoutTVA: {
    textAlign: "center",
    fontWeight: theme.typography.fontWeightLight,
    margin: 0
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  paperAutosuggest: {
    position: "absolute",
    zIndex: 2,
    left: 0,
    width: "100%",
    maxHeight: 150,
    overflowY: "auto"
  },
  paperNature: {
    padding: theme.spacing(2)
  },
  typoNature: {
    marginTop: theme.spacing(2)
  },
  detail: {
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  notchedOutline: {
    borderWidth: 2,
    borderColor: `rgba(0, 0, 0, 0.3) !important`
  }
});

export default withStyles(styles)(InvoiceProduct);
