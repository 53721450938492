import React from "react";
import { Card, CardHeader, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessIcon from "@material-ui/icons/Business";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";
import { Link } from "react-router-dom";

import AdminService from "../../services/AdminService";
import ResetInvoiceToDraft from "../../components/admin/ResetInvoiceToDraft";
// import AddDocumentTemplate from "../../components/admin/AddDocumentTemplate";
import ExportCompanyContent from "../../components/admin/ExportCompanyContent";

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2)
  },
  card: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(),
    "&:hover": {
      cursor: "pointer"
    }
  },
  cardNoClick: {
    minHeight: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing()
  },
  cardIcon: {
    flex: 2,
    color: "#3C3C3C"
  }
}));

export default function SuperAdminHiddenPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickFinancialId = () => {
    AdminService.runContactFinancialIdBatch()
      .then(() => {
        enqueueSnackbar("Synchronisation OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Synchronisation KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleClickInjectAclCancel = () => {
    AdminService.runInjectAclCancelBatch()
      .then(() => {
        enqueueSnackbar("Injection OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Injection KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleClickInjectInvoicesDueDate = () => {
    AdminService.runInjectInvoicesDueDateBatch()
      .then(() => {
        enqueueSnackbar("Batch OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Batch KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleClickInjectEstimatesCashFormDeposits = () => {
    AdminService.runInjectEstimatesCashFormDepositsBatch()
      .then(() => {
        enqueueSnackbar("Batch OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Batch KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleClickInjectOrderFormsCashFormDeposits = () => {
    AdminService.runInjectOrderFormsCashFormDepositsBatch()
      .then(() => {
        enqueueSnackbar("Batch OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Batch KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleClickExportCompanies = () => {
    AdminService.exportCompanies()
      .then(() => {
        enqueueSnackbar("Export OK", { variant: "success" });
      })
      .catch(e => {
        enqueueSnackbar("Export KO", { variant: "error" });
        console.log(" ERROR ", e);
      });
  };

  const handleExportContacts = companyId => {
    if (companyId) {
      AdminService.exportContacts(companyId)
        .then(res => {
          fileDownload(res, "export_clients.csv");
        })
        .catch(e => {
          if (e && e.status === 400) {
            enqueueSnackbar("Rien avec cet ID", { variant: "error" });
          } else {
            enqueueSnackbar("Export KO", { variant: "error" });
          }
          console.log(" ERROR ", e);
        });
    } else {
      alert("RENSEIGNE UN IDENTIFIANT AVANT DE CLIQUER NON ?");
    }
  };

  const handleExportUsers = companyId => {
    AdminService.exportUsers(companyId)
      .then(res => {
        fileDownload(res, "export_utilisateurs.csv");
      })
      .catch(e => {
        console.log(" ERROR ", e);
      });
  };

  const handleExportEstimates = companyId => {
    if (companyId) {
      AdminService.exportEstimates(companyId)
        .then(res => {
          fileDownload(res, "export_devis.csv");
        })
        .catch(e => {
          if (e && e.status === 400) {
            enqueueSnackbar("Rien avec cet ID", { variant: "error" });
          } else {
            enqueueSnackbar("Export KO", { variant: "error" });
          }
          console.log(" ERROR ", e);
        });
    } else {
      alert("RENSEIGNE UN IDENTIFIANT AVANT DE CLIQUER NON ?");
    }
  };

  const handleExportOrderForms = companyId => {
    if (companyId) {
      AdminService.exportOrderForms(companyId)
        .then(res => {
          fileDownload(res, "export_bons.csv");
        })
        .catch(e => {
          if (e && e.status === 400) {
            enqueueSnackbar("Rien avec cet ID", { variant: "error" });
          } else {
            enqueueSnackbar("Export KO", { variant: "error" });
          }
          console.log(" ERROR ", e);
        });
    } else {
      alert("RENSEIGNE UN IDENTIFIANT AVANT DE CLIQUER NON ?");
    }
  };

  const handleExportProducts = companyId => {
    if (companyId) {
      AdminService.exportProducts(companyId)
        .then(res => {
          fileDownload(res, "export_catalogue.csv");
        })
        .catch(e => {
          if (e && e.status === 400) {
            enqueueSnackbar("Rien avec cet ID", { variant: "error" });
          } else {
            enqueueSnackbar("Export KO", { variant: "error" });
          }
          console.log(" ERROR ", e);
        });
    } else {
      alert("RENSEIGNE UN IDENTIFIANT AVANT DE CLIQUER NON ?");
    }
  };

  return (
    <div>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Actions de l&apos;ombre</Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickExportCompanies}>
            <BusinessIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Export entreprises</Typography>
          </Card>
        </Grid>
        {/* <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickFinancialId}>
            <PeopleAltIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Sync Financial ID</Typography>
          </Card>
        </Grid> */}
        {/* <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickInjectAclCancel}>
            <PeopleAltIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Injecter ACL &quot;annuler&quot;</Typography>
          </Card>
        </Grid> */}
        <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickInjectInvoicesDueDate}>
            <PeopleAltIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Ajouter dueDate aux factures</Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickInjectEstimatesCashFormDeposits}>
            <PeopleAltIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Injecter acompte aux devis</Typography>
          </Card>
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Card className={classes.card} variant="outlined" onClick={handleClickInjectOrderFormsCashFormDeposits}>
            <PeopleAltIcon fontSize="large" className={classes.cardIcon} />
            <Typography variant="subtitle1">Injecter acompte aux bons</Typography>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardNoClick} variant="outlined">
            <CardHeader title="Reset facture en brouillon" />
            <ResetInvoiceToDraft />
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.container} spacing={2}>
        {/* <Grid item xs={12} md={6}>
          <Card className={classes.cardNoClick} variant="outlined">
            <CardHeader title="Ajouter un modèle de document" />
            <AddDocumentTemplate />
          </Card>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <Card className={classes.cardNoClick} variant="outlined">
            <CardHeader title="Gestion des catalogues" />
            <Link to="/superosbg/upload-catalog" className={classes.grow}>
              <Typography>Import d&apos;un catalogue</Typography>
            </Link>
            <Link to="/superosbg/replicate-catalog" className={classes.grow}>
              <Typography>Duplication d&apos;un catalogue</Typography>
            </Link>
            <Link to="/superosbg/delete-catalog" className={classes.grow}>
              <Typography>Suppression d&apos;un catalogue</Typography>
            </Link>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.cardNoClick} variant="outlined">
            <CardHeader title="Gestion des annuaires" />
            <Link to="/superosbg/upload-clients" className={classes.grow}>
              <Typography>Import d&apos;un annuaire</Typography>
            </Link>
          </Card>
        </Grid>
      </Grid>
      <Grid container className={classes.container} spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader title="Export devis" />
            <ExportCompanyContent onSubmit={handleExportEstimates} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader title="Export BDC" />
            <ExportCompanyContent onSubmit={handleExportOrderForms} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader title="Export clients" />
            <ExportCompanyContent onSubmit={handleExportContacts} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader title="Export catalogue" />
            <ExportCompanyContent onSubmit={handleExportProducts} />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card} variant="outlined">
            <CardHeader title="Export utilisateurs" />
            <ExportCompanyContent onSubmit={handleExportUsers} allowEmpty />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
