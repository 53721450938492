import React from "react";
import { InputAdornment, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

export default function DepositAmount({ deposit, handleChange }) {
  const type = deposit.isPercentage ? "percentage" : "numeric";

  const onChange = (e, value) => {
    handleChange({ target: { name: "isPercentage", checked: value === "percentage" } });
  };

  return (
    <TextField
      type="number"
      name="inputValue"
      placeholder={deposit.isPercentage ? "10" : "199,00"}
      label="Valeur"
      fullWidth
      variant="outlined"
      value={deposit.inputValue}
      size="small"
      autoComplete="off"
      onChange={handleChange}
      inputProps={{ min: 0, step: 0.1 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <ToggleButtonGroup exclusive onChange={onChange} value={type} style={{ height: 40, marginRight: -14 }}>
              <ToggleButton value="percentage">%</ToggleButton>
              <ToggleButton value="numeric">€</ToggleButton>
            </ToggleButtonGroup>
          </InputAdornment>
        )
      }}
    />
  );
}
