import { inject, observer } from "mobx-react";
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Button, Grid, TextField } from "@material-ui/core";
import DepositAmount from "./DepositAmount";
import { formatCurrency } from "../../../utils/numberFormat";

@inject("newEstimateStore")
@observer
class DepositForm extends React.Component {
  render() {
    const { newEstimateStore } = this.props;
    const { cashFormDeposits } = newEstimateStore;

    return (
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        {cashFormDeposits.map((deposit, index) => (
          <Grid key={`deposit-${index + 1}`} container spacing={2}>
            <Grid item xs={6}>
              <TextField
                name="label"
                placeholder="Acompte"
                label="Libellé de l'acompte"
                fullWidth
                variant="outlined"
                value={deposit.label}
                size="small"
                onChange={newEstimateStore.updateCashFormDeposit(index)}
                helperText={
                  deposit.amount
                    ? `Sera affiché comme "${deposit.label || "Acompte"}${
                        deposit.isPercentage ? ` (${deposit.inputValue}%)` : ""
                      } : ${formatCurrency(deposit.amount, "EUR")}"`
                    : "Ne sera pas affiché"
                }
              />
            </Grid>
            <Grid item xs={5}>
              <DepositAmount
                index={index}
                deposit={deposit}
                handleChange={newEstimateStore.updateCashFormDeposit(index)}
              />
              {/* <TextField
                type="number"
                name="inputValue"
                placeholder={deposit.isPercentage ? "10" : "199,00"}
                label="Valeur"
                fullWidth
                variant="outlined"
                value={deposit.inputValue}
                size="small"
                autoComplete="off"
                onChange={newEstimateStore.updateCashFormDeposit(index)}
                inputProps={{ min: 0, step: 0.1 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ToggleButtonGroup>
                        <ToggleButton value="percentage">%</ToggleButton>
                        <ToggleButton value="numeric">€</ToggleButton>
                      </ToggleButtonGroup>
                    </InputAdornment>
                  )
                }}
              /> */}
            </Grid>
            <Grid item xs={1}>
              {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <span>€</span>
                <Switch
                  color="primary"
                  name="isPercentage"
                  checked={deposit.isPercentage}
                  onChange={newEstimateStore.updateCashFormDeposit(index)}
                />
                <span>%</span>
              </div> */}
              <Button
                size="small"
                color="primary"
                onClick={() => newEstimateStore.removeCashFormDeposit(index)}
                style={{ marginTop: 4, marginBottom: 4 }}
              >
                <DeleteIcon />
              </Button>
            </Grid>
          </Grid>
        ))}
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <Button color="primary" variant="outlined" onClick={newEstimateStore.addCashFormDeposit}>
            Ajouter un acompte
          </Button>
        </div>
      </div>
    );
  }
}

export default DepositForm;
