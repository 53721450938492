import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { withStyles, Typography, Grid, Tabs, Tab } from "@material-ui/core";

import NotFoundPage from "./NotFoundPage";
import UnpaidInvoicesList from "../components/invoice/UnpaidInvoicesList";
import PaymentStats from "../components/payment/PaymentStats";

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  contentContainer: {
    marginTop: -theme.spacing(2)
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
});

@inject("sessionStore")
@observer
class PaymentsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedTab: "LATE" };
  }

  handleChange = (e, selectedTab) => {
    this.setState({ selectedTab });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const { selectedTab } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
        aclType: "oneof",
        requiredFeatures: ["INVOICE"]
      })
    )
      return <NotFoundPage />;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography className={classes.container} variant="h4">
            Paiements
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 20 }}>
          <PaymentStats />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={selectedTab}
              onChange={this.handleChange}
              variant="fullWidth"
              className={classes.tabscontainer}
            >
              <Tab value="LATE" label="En retard" className={classes.tab} />
              <Tab value="PENDING" label="En attente" className={classes.tab} />
            </Tabs>
          </Grid>
        </Grid>
        {selectedTab === "LATE" && (
          <Grid container>
            <Grid item xs={12}>
              <UnpaidInvoicesList state="late" />
            </Grid>
          </Grid>
        )}
        {selectedTab === "PENDING" && (
          <Grid container>
            <Grid item xs={12}>
              <UnpaidInvoicesList state="pending" />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(PaymentsPage);
