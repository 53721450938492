import React, { Component } from "react";
import { Button, Card, CardContent, CardHeader, CircularProgress, Paper, withStyles } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withSnackbar } from "notistack";

import CompanyService from "../../services/CompanyService";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./Wysiswyg.css";
import UploadFileButton from "../button/UploadFileButton";
import NotFoundPage from "../../pages/NotFoundPage";

@inject("sessionStore")
@observer
class Logos extends Component {
  constructor(props) {
    super(props);
    const { sessionStore } = props;
    const { user } = sessionStore;
    const { company } = user;
    this.state = {
      docLogos: [
        { file: null, fileUrl: company.logo1, type: "logo1" },
        { file: null, fileUrl: company.logo2, type: "logo2" },
        { file: null, fileUrl: company.logo3, type: "logo3" },
        { file: null, fileUrl: company.logo4, type: "logo4" },
        { file: null, fileUrl: company.logo5, type: "logo5" }
      ],
      logo: { name: "Télécharger un logotype" },
      secondaryLogo: { name: "Télécharger un logotype secondaire" },
      loading: false
    };
  }

  manageLogos = async () => {
    const { docLogos } = this.state;
    const { sessionStore } = this.props;
    const { company } = sessionStore.user;

    docLogos.forEach(async docLogo => {
      if (docLogo.file) {
        const logo = new FormData();
        logo.append("logo", docLogo.file);
        logo.append("type", docLogo.type);
        await CompanyService.uploadLogo(logo).catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors du chargement du logo", {
            variant: "error"
          });
        });
      } else if (docLogo.fileUrl === null && company[docLogo.type] !== null) {
        await CompanyService.deleteLogo({ type: docLogo.type, name: company[docLogo.type] }).catch(() => {
          const { enqueueSnackbar } = this.props;
          enqueueSnackbar("Une erreur est survenue lors de la suppression du logo", {
            variant: "error"
          });
        });
      }
    });
  };

  handleSubmit = async () => {
    await this.manageLogos();
    setTimeout(() => {
      const { sessionStore } = this.props;
      sessionStore.init();
      const { enqueueSnackbar } = this.props;
      enqueueSnackbar("Les paramètres pour les factures ont été mis à jour", {
        variant: "success"
      });
    }, 500);
  };

  handleLogoInput = ({ file, id }) => {
    const { docLogos } = this.state;
    this.setState({
      docLogos: docLogos.map(item => (item.type === id ? { ...item, file } : item))
    });
  };

  handleRemoveInvoiceLogo = logoId => {
    const { docLogos } = this.state;
    this.setState({
      docLogos: docLogos.map(item =>
        item.type === logoId ? { ...item, file: null, fileUrl: item.file === null ? null : item.fileUrl } : item
      )
    });
  };

  handleChangeLogo = () => {
    const logo = new FormData();
    logo.append("logo", this.uploadLogo.files[0]);
    logo.append("type", "logo");

    this.setState({ loading: true });
    CompanyService.upload(logo)
      .then(resp => {
        this.setState({ loading: false, logo: { name: resp } });
        window.location.reload();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue pendant le téléchargement du logo", {
          variant: "error"
        });
        this.setState({ logo: { name: "Erreur lors de l'upload" } });
      });
  };

  handleChangeSecondaryLogo = () => {
    const _logo = new FormData();
    _logo.append("logo", this.uploadSecondaryLogo.files[0]);
    _logo.append("type", "secondaryLogo");

    this.setState({ loading: true });
    CompanyService.upload(_logo)
      .then(resp => {
        this.setState({ loading: false, secondaryLogo: { name: resp } });
        window.location.reload();
      })
      .catch(() => {
        const { enqueueSnackbar } = this.props;
        enqueueSnackbar("Une erreur est survenue pendant le téléchargement du logotype secondaire", {
          variant: "error"
        });
        this.setState({ logo: { name: "Erreur lors de l'upload" } });
      });
  };

  render() {
    const { classes, sessionStore } = this.props;
    const companyLogo = sessionStore.user.company.logo;
    const companySecondaryLogo = sessionStore.user.company.secondaryLogo;
    const { docLogos, loading, logo, secondaryLogo } = this.state;

    if (
      !sessionStore.userHasAccess({
        requiredAcls: ["SETTING_WRITE", "SETTING_READ"],
        aclType: "oneof",
        requiredFeatures: ["SETTING"]
      })
    ) {
      return <NotFoundPage />;
    }

    const LogoOrNotLogo = ({ index, docLogo }) => {
      if (docLogo.file) {
        return (
          <img src={URL.createObjectURL(docLogo.file)} className={classes.invoiceLogoPicture} alt={docLogo.type} />
        );
      }
      if (docLogo.fileUrl) {
        return (
          <img
            src={`${process.env.API_URL}/public/logo/${docLogo.fileUrl}`}
            className={classes.invoiceLogoPicture}
            alt={docLogo.type}
          />
        );
      }
      return <span>{index}</span>;
    };

    const canEdit = sessionStore.userHasAccess({
      requiredAcls: ["SETTING_WRITE"],
      requiredFeatures: ["SETTING"]
    });

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <Typography variant="h4">Logos</Typography>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <Card className={classes.wrapper}>
              <CardHeader title="Logotype" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={6} className={classes.logotypeInputContainer}>
                    <input
                      accept="image/*"
                      id="uploadLogo"
                      type="file"
                      ref={ref => {
                        this.uploadLogo = ref;
                      }}
                      style={{ display: "none" }}
                      onChange={this.handleChangeLogo}
                    />
                    <label htmlFor="uploadLogo">
                      <Button component="span" variant="outlined" color="primary" disabled={loading || !canEdit}>
                        {logo.name}
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.logotypeContainer}>
                    {companyLogo && <img src={`${process.env.API_URL}/public/logo/${companyLogo}`} height="100" />}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card className={classes.wrapper}>
              <CardHeader title="Logotype secondaire" className={classes.cardheader} />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={6} className={classes.logotypeInputContainer}>
                    <input
                      accept="image/*"
                      id="uploadSecondaryLogo"
                      type="file"
                      ref={ref => {
                        this.uploadSecondaryLogo = ref;
                      }}
                      style={{ display: "none" }}
                      onChange={this.handleChangeSecondaryLogo}
                    />
                    <label htmlFor="uploadSecondaryLogo">
                      <Button component="span" variant="outlined" color="primary" disabled={loading || !canEdit}>
                        {secondaryLogo.name}
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </label>
                  </Grid>
                  <Grid item xs={12} sm={6} className={classes.logotypeContainer}>
                    {companySecondaryLogo && (
                      <img src={`${process.env.API_URL}/public/logo/${companySecondaryLogo}`} height="100" />
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card className={classes.wrapper}>
              <CardHeader
                title="Logos"
                className={classes.cardheader}
                subheader="Vous avez la possibilité d'ajouter jusqu'à 5 logos à la fin de chaque document"
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={12} md={6} className={classes.logosInputsContainer}>
                    <div>
                      {docLogos.map((docLogo, index) => (
                        <Grid item xs={12} key={docLogo.type}>
                          <UploadFileButton
                            id={docLogo.type}
                            onChange={this.handleLogoInput}
                            label={`Logo ${index + 1}`}
                            disabled={!canEdit}
                          />
                          <Button
                            component="span"
                            variant="outlined"
                            className={classes.invoiceLogoRemoveButton}
                            disabled={!canEdit || (!docLogo.file && !docLogo.fileUrl)}
                            onClick={() => this.handleRemoveInvoiceLogo(docLogo.type)}
                          >
                            Supprimer
                          </Button>
                        </Grid>
                      ))}
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.invoicePaper}>
                      <Grid container className={classes.invoiceLogosWrapper}>
                        {docLogos.map((docLogo, index) => (
                          <Grid item xs={2} className={classes.invoiceLogo} key={docLogo.type}>
                            <LogoOrNotLogo docLogo={docLogo} index={index + 1} />
                          </Grid>
                        ))}
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {canEdit && (
          <div className="react-draft-button">
            <div>
              <Button className={classes.button} variant="contained" color="primary" onClick={this.handleSubmit}>
                Enregistrer les paramètres
              </Button>
            </div>
          </div>
        )}
      </Grid>
    );
  }
}

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(2),
    width: "100%"
  },
  paper: {
    padding: theme.spacing(2)
  },
  wrapper: {
    marginTop: theme.spacing(3)
  },
  cardheader: {
    paddingBottom: 0
  },
  invoicePaper: {
    borderRadius: 0,
    padding: 10,
    height: 250,
    paddingTop: 50,
    alignItems: "flex-end",
    display: "flex"
  },
  invoiceLogosWrapper: {
    justifyContent: "center",
    alignItems: "flex-end"
  },
  invoiceLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    minHeight: 40,
    padding: 5,
    margin: 2,
    border: "1px solid #E6E6E6"
  },
  invoiceLogoPicture: {
    maxWidth: "100%",
    maxHeight: 200
  },
  invoiceLogoRemoveButton: {
    borderColor: "red",
    color: "red"
  },
  logosInputsContainer: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column"
  },
  logotypeInputContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(2)
    }
  },
  logotypeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

export default withSnackbar(withStyles(styles)(Logos));
