import { CircularProgress, Grid } from "@material-ui/core";
import { inject, observer } from "mobx-react";
import React, { useMemo } from "react";
import ScheduleIcon from "@material-ui/icons/Schedule";
import AssignmentLateOutlined from "@material-ui/icons/AssignmentLateOutlined";
import EuroIcon from "@material-ui/icons/Euro";

import { useQuery } from "@tanstack/react-query";
import PaymentService from "../../services/PaymentService";
import Widget from "../dashboard/Widget";
import { formatCurrency } from "../../utils/numberFormat";

const sumPendingAndLate = stats => {
  return {
    amount: stats.pending.amount + stats.late.amount,
    count: stats.pending.count + stats.late.count
  };
};

function PaymentStats({ sessionStore }) {
  if (
    !sessionStore.userHasAccess({
      requiredAcls: ["INVOICE_MY", "INVOICE_READ"],
      aclType: "oneof",
      requiredFeatures: ["INVOICE"]
    })
  )
    return null;

  const { data } = useQuery({
    queryKey: ["payment_stats"],
    queryFn: () => PaymentService.getPaymentStats(),
    refetchOnWindowFocus: false,
    retry: 1
  });

  const _totalData = useMemo(() => (data ? sumPendingAndLate(data) : undefined), [data]);

  return data ? (
    <Grid container spacing={2} justify="space-evenly">
      <Grid item xs={12} md={3}>
        <Widget
          title="En retard"
          count={data.late.count}
          amount={formatCurrency(data.late.amount, "EUR")}
          color="#d9534f"
          icon={<AssignmentLateOutlined fontSize="large" style={{ color: "#FFF" }} />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Widget
          title="En attente"
          count={data.pending.count}
          amount={formatCurrency(data.pending.amount, "EUR")}
          color="#f0ad4e"
          icon={<ScheduleIcon fontSize="large" style={{ color: "#FFF" }} />}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Widget
          title="Total"
          count={_totalData.count}
          amount={formatCurrency(_totalData.amount, "EUR")}
          color="#777"
          icon={<EuroIcon fontSize="large" style={{ color: "#FFF" }} />}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container justify="center">
      <CircularProgress />
    </Grid>
  );
}

export default inject("sessionStore")(observer(props => <PaymentStats {...props} />));
